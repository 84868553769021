﻿import { Attribution } from 'ol/control';
import ImageLayer from 'ol/layer/Image';
import Map from 'ol/Map';
import OSM from 'ol/source/OSM';
import Projection from 'ol/proj/Projection';
import Stamen from 'ol/source/Stamen';
import Static from 'ol/source/ImageStatic';
import Tile from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';
import View from 'ol/View';
import WMSCapabilities from 'ol/format/WMSCapabilities';
import { fromLonLat } from 'ol/proj';
import { transformExtent } from 'ol/proj';
import { ridge2ClientOptions } from './ridge2ClientOptions';

export class ridge2Client {
    options: ridge2ClientOptions;

    progressBar: HTMLElement;
    progressBarInner: HTMLElement;

    radarLayer: Tile;
    maxTilesLoading = 8;

    radarTimeValues: Date[] = [];
    radarTimeIndex = -1;
    radarTime: Date;

    animateStartDelay = 10000;
    lastRadarTimePause = 1000;

    map: Map;
    arcgisUrl = "https://idpgis.ncep.noaa.gov/arcgis/rest/services/radar/radar_base_reflectivity_time/ImageServer/exportImage?";
    radarImageLayersDict: { [id: string]: ImageLayer } = {};

    constructor() {
        var params = this.ParseURLParams(window.location.search);
        this.options = ridge2ClientOptions.parseParams(params);

        this.progressBar = document.querySelector('.progressBar');
        this.progressBarInner = document.querySelector('.progressBarInner');
        this.progressBar.style.display = "block";

        console.log("starting client with options", this.options);
        this.create(this.options);
    }

    ParseURLParams(url: string): any {
        var queryStart = url.indexOf("?") + 1;
        var queryEnd = url.indexOf("#") + 1 || url.length + 1;
        var query = url.slice(queryStart, queryEnd - 1);

        if (query === url || query === "") return {};

        var params: any = {};
        var nvPairs = query.replace(/\+/g, " ").split("&");

        for (var i = 0; i < nvPairs.length; i++) {
            var nv = nvPairs[i].split("=");
            var n = decodeURIComponent(nv[0]);
            var v = decodeURIComponent(nv[1]);
            //if ( !(var n=0; n < params.length; n++) ) {
            if (!(n in params)) {
                params[n] = [];
            }
            //params[n].push(nv.length === 2 ? v : null);
            params[n] = v;
        }
        return params;
    }

    create(options: ridge2ClientOptions) {
        var mapTileSource = new OSM();
        if (options.tileSource == "StamenTerrain") {
            mapTileSource = new Stamen({
                layer: 'terrain'
            });
        }

        var projection = new Projection({
            code: 'EPSG:3857',
            units: 'm',
        });

        var view = new View({
            center: fromLonLat([options.center.longitude, options.center.latitude]),
            zoom: options.zoom,
            projection: projection
        });

        var mapLayer = new Tile({
            source: mapTileSource
        });

        var radarOpacity = .5;
        if (options.opacity) {
            radarOpacity = options.opacity;
        }

        var brefSource = {
            url: 'https://opengeo.ncep.noaa.gov/geoserver/conus/conus_bref_qcd/ows?',
            getCapabilitiesUrl: 'https://opengeo.ncep.noaa.gov/geoserver/conus/conus_bref_qcd/ows?service=wms&version=1.3.0&request=GetCapabilities',
            layers: 'conus_bref_qcd'
        };
        var crefSource = {
            url: 'https://opengeo.ncep.noaa.gov/geoserver/conus/conus_cref_qcd/ows?',
            getCapabilitiesUrl: 'https://opengeo.ncep.noaa.gov/geoserver/conus/conus_cref_qcd/ows?service=wms&version=1.3.0&request=GetCapabilities',
            layers: 'conus_cref_qcd'
        };
        var tileWmsSource = crefSource;

        this.radarLayer = new Tile({
            extent: extent,
            source: new TileWMS({
                url: tileWmsSource.url,
                params: { 'LAYERS': tileWmsSource.layers, 'VERSION': '1.1.1', 'TILED': true }
            }),
            opacity: radarOpacity
        });

        this.map = new Map({
            target: 'map',
            layers: [
                mapLayer
            ],
            view: view,
            controls: [new Attribution({ collapsible: false })],
            maxTilesLoading: 8
        });

        if (options.fitBounds) {
            var extent = transformExtent(
                [
                    options.fitBounds[0].longitude, options.fitBounds[0].latitude,
                    options.fitBounds[1].longitude, options.fitBounds[1].latitude
                ],
                "EPSG:4326", "EPSG:3857"
            );
            view.fit(extent);
        }

        if (options.animate) {
            var req = new XMLHttpRequest();
            var client = this;
            req.addEventListener("load", function () {

                var parser = new WMSCapabilities();
                var capabilities = parser.read(this.responseText);
                var timeValues = capabilities.Capability.Layer.Layer[0].Dimension[0].values.split(',');
                client.radarTimeValues = [];
                for (var i = 0; i < timeValues.length; i++) {
                    client.radarTimeValues.push(new Date(timeValues[i]));
                }
                client.radarTimeValues = client.radarTimeValues.sort();
                console.log("animating with times:", client.radarTimeValues);
                setTimeout(function () {
                    //client.animateRadar();
                }, client.animateStartDelay);
                client.getRadarImages();
            });
            req.open("GET", tileWmsSource.getCapabilitiesUrl);
            req.send();
        }
        else {
            this.getCurrentRadarImage();
        }
    }

    getCurrentRadarImage() {
        var extent = this.map.getView().calculateExtent();
        var size = window.innerWidth + ',' + window.innerHeight;
        var url = `${this.arcgisUrl}bbox=${extent.join(',')}&size=${size}&f=image`;
        var layer = new ImageLayer({
            opacity: this.options.opacity,
            visible: true
        });
        layer.setSource(new Static({
            url: url,
            projection: 'EPSG:3857',
            imageExtent: extent,
            imageSmoothing: true
        }));
        this.map.addLayer(layer);
    }

    getRadarImages() {
        var extent = this.map.getView().calculateExtent();
        var size = window.innerWidth + ',' + window.innerHeight;
        for (var i = 0; i < this.radarTimeValues.length; i++) {
            var time = this.radarTimeValues[i];
            var url = `${this.arcgisUrl}bbox=${extent.join(',')}&size=${size}&f=image`;
            if (i < this.radarTimeValues.length - 1) {
                url += `&time=${time.getTime()}`;
            }
            var layer = new ImageLayer({
                opacity: this.options.opacity,
                visible: false
            });
            layer.setSource(new Static({
                url: url,
                projection: 'EPSG:3857',
                imageExtent: extent,
                imageSmoothing: true
            }));
            this.map.addLayer(layer);
            this.radarImageLayersDict[time.getTime()] = layer;
        }
        this.animateRadar();
    }

    animateRadar = () => {
        if (this.radarTimeIndex < 0) {
            this.radarTimeIndex = 0;
        }
        else {
            this.radarTimeIndex += this.options.animateIncrement;
        }
        if (this.radarTimeIndex >= this.radarTimeValues.length) {
            this.radarTimeIndex = this.radarTimeValues.length - 1;
        }

        for (var t of this.radarTimeValues) {
            var l = this.radarImageLayersDict[t.getTime()];
            if (l.getVisible()) {
                l.setVisible(false);
            }
        }
        this.radarTime = this.radarTimeValues[this.radarTimeIndex];
        //var timeString = this.radarTime.toISOString();
        //(<TileWMS>this.radarLayer.getSource()).updateParams({ 'TIME': timeString });
        this.radarImageLayersDict[this.radarTime.getTime()].setVisible(true);

        var lastRadarTime = this.radarTimeValues[this.radarTimeValues.length - 1];
        var cycleDuration = lastRadarTime.getTime() - this.radarTimeValues[0].getTime();
        var cycleProgress = (this.radarTime.getTime() - this.radarTimeValues[0].getTime()) / cycleDuration;

        this.progressBarInner.style.left = cycleProgress * 99 + "%";

        var delay = this.options.animateInterval;
        if (this.radarTimeIndex >= this.radarTimeValues.length - 1) {
            delay = this.lastRadarTimePause;
            this.radarTimeIndex = -1;
        }

        setTimeout(() => {
            this.animateRadar();
        }, delay);
    }
}